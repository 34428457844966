import React from 'react';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";
import CTA from "../../components/cta";

const CosellingZendeskCaseStudies = ({location}) => (
  <Layout>
    <section className="d-flex p-6 bg-pattern-trihex">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <h1>Co-selling Zendesk: Case Studies</h1>
            <h4>Close Your Sales Calls With Help From 729 Solutions</h4>
          </div>
        </div>
      </div>
    </section>

    <section className="p-6 bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="bg-white mb-4 pt-3 pb-4 px-5 rounded w-75">
              <StaticImage src="../../images/clients/zendesk-customers/asg.png" alt="ASG Technologies Logo"/>
            </div>
            <h4 className="text-secondary-light">Client</h4>
            <p className="text-white">ASG Technologies</p>
          </div>

          <div className="col-md-8 ml-auto">
            <h2 className="text-white">Case Study #1: ASG Technologies</h2>
            <h4 className="text-secondary-light">Business Scenario</h4>
            <p className="text-white">
              Zendesk Account Executive Jeff Gershon came to 729 Solutions to help him address the concerns of <a
              href="https://www.asg.com/" class="text-light">ASG Technologies</a>. At the time of the first call, ASG
              had been considering buying Zendesk for six months. The stakeholders knew that they needed to make a
              change, but had a number of fears that were blocking them from making any decisions.
            </p>
            <p className="text-white">
              Graham Silbermann, 729 Solutions’ Partner Sales Lead, joined Jeff on two calls with ASG to walk through
              Zendesk and address any concerns. By bringing in 729 to talk about services the customer suddenly
              addressed all questions that were preventing them from moving to Zendesk:
            </p>
            <ul className="text-white">
              <li>
                How would they move their data from their closed legacy database to Zendesk?
              </li>
              <li>
                How many people-hours would be needed to administer Zendesk? Would they need to hire a Full-Time
                Employee (FTE)
              </li>
              <li>
                Are there best practices for implementing Zendesk?
              </li>
              <li>
                What if they missed something important, or did something wrong?
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className="p-6 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h4>Our Role</h4>
            <p>
              Sales Enablement
            </p>
            <p>
              Zendesk Implmentation
            </p>
            <p>
              Zendesk Maintenance
            </p>
          </div>

          <div className="col-md-8 ml-auto">
            <h4>Outcome</h4>
            <p>
              ASG closed in one week of the co-selling effort between 729 Solutions and Zendesk.
            </p>
            <p>
              Since then 729 has successfully implemented Zendesk to their custom workflows and trained ASG to use the
              product. 729 Solutions has stayed working for ASG in a long term maintenance contract to keep supporting
              the growth of the account and Zendesk has another satisfied customer. ASG has improved their overall
              customer service experience and now provides consistent and quality support for their customers. Their
              employees are equipped to handle any customer service need and feel confident in their ability to use
              Zendesk to respond to tickets, answer questions and track information.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="p-6 bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="bg-white mb-4 py-5 px-5 rounded w-75">
              <StaticImage src="../../images/clients/zendesk-customers/ted.png" alt="ASG Technologies Logo"/>
            </div>
            <h4 className="text-secondary-light">Client</h4>
            <p className="text-white">TED Talks</p>
          </div>

          <div className="col-md-8 ml-auto">
            <h2 className="text-white">Case Study #2: TED Talks</h2>
            <h4 className="text-secondary-light">Business Scenario</h4>
            <p className="text-white">
              <a href="https://www.ted.com/" class="text-light">TED Talks</a> have become a well known throughout the
              world for their focused agenda: to make great ideas accessible and spark conversation. 729 Solutions was
              referred to TED who were evaluating a few customer success tools including Kustomer, Help Scout, and
              Intercom. TED knew they would need services no matter what product they bought, but were fearful of making
              the wrong decision and wreaking havoc on their non-profit mission.
            </p>
            <p className="text-white">
              Zendesk Account Executive Matthew Burke and 729 Partner Sales Lead Graham Silbermann worked together in
              parallel to address the fears of the client and close the deal. Graham provided Matt with vital business
              intelligence when TED seemed to be moving away from Zendesk. In this hyper competitive sales deal 729 made
              sure TED was given:
            </p>
            <ul className="text-white">
              <li>
                Appropriate and timely value propositions about Zendesk features.
              </li>
              <li>
                Detailed information about integrations.
              </li>
              <li>
                Demonstrated ease-of-use of Zendesk by the TED employees.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className="p-6 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h4>Our Role</h4>
            <p>
              Sales Enablement
            </p>
            <p>
              Customer Success Support
            </p>
            <p>
              Desk to Zendesk Migration
            </p>
          </div>

          <div className="col-md-8 ml-auto">
            <h4>Outcome</h4>
            <p>
              By closely aligning with sales goals, the deal was closed.
            </p>
            <p>
              729 will be helping TED migrate from Desk, implement Zendesk and deliver on their go live timeline. Ted
              Talks are free to focus on their mission of bringing amazing ideas from individuals to the wider world,
              and sparking conversations that will change the future.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="p-6">
      <div className="container">
        <div className="row">
          <div className="col">
            <h3>Next Step: Schedule a Call With 729 Solutions to Learn How We Can Help You Close Your Zendesk Deals.</h3>
            <CTA href="https://calendly.com/graham-729" text="SCHEDULE A CALL" className="btn btn-primary-light"/>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default CosellingZendeskCaseStudies;
